<template lang="pug">
  .link-wrapper(
    v-if="!(isAllLinksHidden && nested)"
    :class="{ active: isActive(link) || (nested && isNestedActive(nested)) }"
  )
    template(v-if="nested")
      li.link(
        v-if="!isAllLinksHidden"
        :class="{ opened, 'parent-active': isNestedActive(nested), active: isActive(link) }"
        @click="$emit('nested-links-toggle')"
        :data-cy="dataCy"
      )
        AppIcon(:name="icon")
        .link-content
          span {{ $t(title) }}
          .link-arrow(
            :class="{ opened }"
          )
            FaIcon(icon="chevron-down")
      ul.nested(
        :class="{ opened }"
      )
        li.nested-link(
          v-for="nestedLink in nested"
          v-if="isShownNestedLink(nestedLink) && !nestedLink.hidden"
          :class="{ opened, active: isActive(nestedLink), disabled: nestedLink.disabled }"
          :key="nestedLink.title"
        )
          span(v-if="nestedLink.disabled") {{ $t(nestedLink.title) }}
          RouterLink(
            v-else
            :event="getEventType(nestedLink)"
            :to="{ name: nestedLink.page }"
          ) {{ $t(nestedLink.title) }}
    template(v-else)
      li.link(
        v-if="link.permissionType === undefined || !hasDenyPermission(link.permissionType)"
        :class="{ active: isActive(link) }"
      )
        RouterLink(
          :event="getEventType(link)"
          :to="{ name: page }"
        ).d-flex.align-items-center
          AppIcon(:name="icon")
          .link-content
            component(
              v-if="link.component"
              :is="link.component"
              :title="$t(title)"
            )
            span(v-else) {{ $t(title) }}
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { some, every, includes, isEmpty, find, map } from "lodash-es"

  export default {
    props: {
      link: Object,
      isSidebarHidden: Boolean
    },

    components: {
      AppIcon: () => import("@/components/elements/AppIcon")
    },

    mixins: [withPermissions],

    data() {
      return {
        eventType: "click",
        title: this.link.title,
        page: this.link.page,
        icon: this.link.icon,
        dataCy: this.link.dataCy
      }
    },

    computed: {
      nested() {
        return this.link.nested
      },

      opened() {
        return this.link.opened
      },

      isAllLinksHidden() {
        return every(this.nested, link => !this.isShownNestedLink(link))
      },

      allowedPageNames() {
        return map(this.$store.state.pages, "name")
      }
    },

    methods: {
      // Prevents active navigation link from being re-clicked
      // to avoid unwanted redirect in nested route tabs
      getEventType(link) {
        return this.isActive(link) ? "" : this.eventType
      },

      isNestedActive(nestedLinks) {
        return some(nestedLinks, ({ page, subPages }) => this.$route.name === page || this.isChildActive(subPages))
      },

      isChildActive(subPages = []) {
        return includes(subPages, this.$route.name)
      },

      isActive(link) {
        const routeName = link.page

        if (routeName) {
          return this.$route.name === routeName || this.isChildActive(link.subPages)
        } else {
          return this.isNestedActive(this.nested) && this.isSidebarHidden
        }
      },

      isShownNestedLink({ hidden, permissionType, hideFromSuperadmin, onlySuperadmin, page, subPages }) {
        if (hidden) {
          return false
        } else if (!this.isSuperadmin && onlySuperadmin) {
          return false
        } else if (this.isSuperadmin || this.isOrganizationAdmin) {
          return !hideFromSuperadmin
        } else if (permissionType === undefined && !isEmpty(subPages)) {
          const route = find(this.$router.options.routes, { name: page })
          return this.checkChildrenPermissions(route?.children)
        } else {
          return this.checkPermissionType(permissionType)
        }
      },

      checkChildrenPermissions(children) {
        if (isEmpty(children)) return false

        return some(children, childLink => {
          return this.checkPermissionType(childLink.meta.permissionType)
        })
      },

      checkPermissionType(permissionType) {
        return this.isAllowedPage(permissionType) && !this.hasDenyPermission(permissionType)
      },

      isAllowedPage(permissionType) {
        // permissionType === page.name
        return includes(this.allowedPageNames, permissionType)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/transitions.sass"

  @mixin link
    align-items: center
    display: flex
    cursor: pointer
    font-size: 0.8rem
    font-weight: 400
    height: $link-container-height
    padding: 0 12px
    letter-spacing: 0.23px
    border-radius: 5px
    transition: all 0.25s, background-color 0.05s linear

    a
      border: none
      color: $default-black
      text-decoration: none
      outline: none
      width: 100%

    &:not(.nested-link)
      border: 1px solid transparentize($default-purple-light, 0.5)

      &.opened
        border-radius: 5px 5px 0 0

    &.nested-link
      padding: 0 10px

    &.active
      background-color: $default-purple-light

      a
        color: $default-purple

    .app-icon
      align-items: center
      display: flex
      height: auto
      justify-content: center
      margin-right: 10px
      max-width: 16px
      min-width: 16px

      ::v-deep
        .svg-fillable
          fill: $default-purple

    &:not(.active):not(.parent-active):hover
      background-color: transparentize($default-purple-light, 0.5)

    &-content
      align-items: center
      display: flex
      justify-content: space-between
      max-width: 100%
      opacity: 1
      white-space: nowrap
      width: 100%

    &-arrow
      transition: transform 0.25s ease

      &.opened
        transform: rotate(180deg)

  .link-wrapper
    width: 100%
    border-radius: 6px
    transition: all 0.1s linear

    &:hover, &.active
      box-shadow: 0 2px 10px -8px transparentize($default-black, 0.2)

    .link
      +link

      &.parent-active
        color: $default-purple
        background: $default-purple-light

    a
      height: inherit

    .nested
      display: flex
      flex-direction: column
      gap: 0.25rem
      list-style: none
      max-height: 0px
      overflow: hidden
      transition: max-height 0.25s linear, padding 0.25s linear
      padding: 0 5px

      &.opened
        background: transparentize($default-purple-light, 0.9)
        border: 1px solid transparentize($default-purple-light, 0.7)
        border-top: none
        padding: 10px 5px
        border-radius: 0 0 5px 5px
        position: relative
        height: fit-content
        max-height: 1000px
        transition: max-height 0.25s linear, padding 0.25s linear

      &-link
        +link
        font-weight: 400
        height: 30px
        line-height: 30px
        max-height: 100px
        opacity: 1

        &.active,
        &.active *
          cursor: default

        &:not(.opened)
          max-height: 0
          opacity: 0

      .disabled
        cursor: not-allowed !important
        color: $default-gray !important

    .separator
      background-color: $border-element-color
      border: none
      color: $border-element-color
      height: 1px
      margin: 5px 0 10px
</style>
